const HTML_ACTIVE_CLASS = 'page-has-expanded-site-nav';
const HEADER_NAV_EXPANDED_CLASS = 'site-header--expanded';

const toggleNav = (nav, forceHide = false) => {
  const shouldHide = forceHide || nav.classList.contains(HEADER_NAV_EXPANDED_CLASS);
  const classListToggle = shouldHide ? 'remove' : 'add';

  // Toggle classes
  document.documentElement.classList[classListToggle](HTML_ACTIVE_CLASS);
  nav.classList[classListToggle](HEADER_NAV_EXPANDED_CLASS);
};

export const toggleHandler = (el, e) => {
  const nav = document.querySelector('.js-site-header');
  e.preventDefault();
  el.setAttribute('aria-expanded', el.getAttribute('aria-expanded') === 'false');
  toggleNav(nav);
};
