const show = el => el.setAttribute('aria-hidden', false);
const hide = el => el.setAttribute('aria-hidden', true);

const filterList = context => e => {
  const clickedButton = e.target;
  const filterType = clickedButton.getAttribute('data-filter');
  const listItems = [...context.list.children];

  if (clickedButton.getAttribute('aria-pressed') === 'true') {
    clickedButton.setAttribute('aria-pressed', false);
    return listItems.map(show);
  }

  [...context.filterButtons].map(button => button.setAttribute('aria-pressed', false));
  clickedButton.setAttribute('aria-pressed', true);

  return listItems.map(item => {
    return item.getAttribute('data-label') === filterType
      ? show(item)
      : hide(item);
  });
};

export const enhancer = (el) => {
  const context = {
    filterButtons: el.querySelectorAll('button'),
    list: document.getElementById(el.dataset.controls),
  };

  [...context.filterButtons].map(button => {
    button.addEventListener('click', filterList(context));
  });
};
