import { htmlToElement } from './util';

/**
* Lazy-load podcast embeds after the user has chosen to play it.
*/
const PLAYING_CLASS = 'is-playing-podcast';

const embedPodcast = el => {
  const html = el.getAttribute('data-podcast-html');
  if (!html) {
    return;
  }
  const embed = html;
  const container = htmlToElement(`
    <div class="podcast-embed js-podcast-container">${embed}</div>
  `);

  const embedToElement = document.querySelector(
    `#${el.getAttribute('data-podcast-embed-element')}`
  );
  const classToElement = document.querySelector(
    `#${el.getAttribute('data-podcast-class-element')}`
  );

  if (embedToElement) {
    embedToElement.appendChild(container);
    classToElement.classList.add(PLAYING_CLASS);
  } else {
    el.parentNode.classList.add(PLAYING_CLASS);
    el.parentNode.appendChild(container);
  }
};

export const handler = (el, e) => {
  e.preventDefault();
  embedPodcast(el);
};
